// お問合せ完了ページ

import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";

const Thanks = ({ data }) => (
  <>
    <Layout>
      <section className="thanks">
        <h2>お問い合わせいただきありがとうございました。</h2>
        <p>近日中に、ご記入いただいたメールアドレスにご連絡いたします。</p>
        <div className="backHome">
          <Link to={`/`}>
            <button>ホームに戻る</button>
          </Link>
        </div>
      </section>
    </Layout>
  </>
);

export default Thanks;
